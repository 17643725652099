<template>
  <div class="canvas-list">
    <a-spin :spinning="loading">
      <div class="canvas-wrap" :class="{ 'canvas-mask': isDisabled }" :style="{ height: `${processingArea.height}px` }">
        <canvas-m-canvas :id="currentId" :unit-data="unitData" :canvas="canvas" @zoomed="handleZoomed" @selected="handleSelected" />
        <canvas-zoom-control :editor-store="editorStore" class="zoom-control" />

        <div v-show="!loading" class="btns">
          <span
            v-for="item in canvasList"
            :key="item.id"
            :class="['btn', item.id == currentId ? 'active' : '']"
            :title="formatTitle(item.title)"
            @click="changeCanvas(item.id)"
          >
            {{ formatTitle(item.title) }}
          </span>
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script lang="ts" setup>
import type { CanvasData } from '@/types/canvas';

const props = withDefaults(
  defineProps<{
    editorStore: any;
    projectStore: any;
    canvasList: CanvasData[] | undefined;
    currentId: string | undefined;
    loading?: boolean | undefined;
    isDisabled?: boolean;
    currentSelectDisplay: string[];
    processingArea?: {
      width: number;
      height: number;
    };
  }>(),
  { processingArea: () => ({ width: 735, height: 325 }), loading: false },
);
type emitsType = {
  (event: 'close'): void;
  (event: 'selectDisplay', value: any): void;
  (event: 'clickCanvasBtn', value: string): void;
};
const emit = defineEmits<emitsType>();
const unitData = {
  unit: 'mm',
  pixelPerUnit: 1,
  fractionDigits: 1,
};
let projectManager = ref(null) as any;
let mCanvasManager = ref(null) as any;
const canvas = ref(null) as any;
const init = async () => {
  const classMCanvasManager = await import('@/utils/canvas/manager');
  mCanvasManager.value = classMCanvasManager.MCanvasManager;
  (window as any).MCMG = mCanvasManager.value;
  const project = await import('@/utils/canvas/project');
  projectManager.value = project.ProjectManager;
};
const changeCanvas = async (id: string) => {
  props.editorStore.editorChangeCanvas(id);
  clickCanvasBtn();
};
const handleZoomed = (scaled: number) => {
  console.log('scaled: ', scaled);
  props.editorStore.updateCurrentEditorState({ scaled });
};

// const handleCreated = (data: { id: string; canvas: MCanvas }) => {
//   console.log(['handleCreated-data: ', data]);
//   const { canvas } = data;
//   props.editorStore.editorOnSelected(canvas.currentState);
// };
const handleSelected = (res: any) => {
  const selected = res.getData().selectedJSON;
  emit('selectDisplay', selected);
};

const formatTitle = (str: string) => {
  const res = str.replace(/({panel})/g, 'Canvas');
  return res;
};
// 点击切换画布的时候设置一下画布的选中状态，需异步处理
const clickCanvasBtn = () => {
  setTimeout(() => {
    props.editorStore.canvas?.selectSpecDisplays(props.currentSelectDisplay);
  }, 0);
};
// 画布更换的时候，根据元素设置缩放比例
watch(
  () => props.editorStore?.canvas,
  () => {
    if (!(props.editorStore && props.editorStore.canvas)) {
      return;
    }
    setTimeout(() => {
      resizeCanvasArea();
    }, 10);
  },
);

/**
 *
 * 计算画布的缩放比例，使画布中的元素全部显示
}
*/
const resizeCanvasArea = (
  option = {
    processingArea: {
      width: props.processingArea.width,
      height: props.processingArea.height,
    },
  },
) => {
  console.log('props.editorStore?: ', props.editorStore);
  console.log('props.editorStore?: ', props.editorStore.canvas);

  props.editorStore?.canvas.resizeViewport({
    viewportWidth: option.processingArea.width,
    viewportHeight: option.processingArea.height,
  });
  // 刻度坐标
  const allElesXY: any = {
    minX: Infinity,
    minY: Infinity,
    maxX: -Infinity,
    maxY: -Infinity,
    center: [null, null],
  };
  const allData = props.editorStore.canvas.exportCanvasJSON();
  allData.map((item: any) => {
    if (item.x < allElesXY.minX) {
      allElesXY.minX = item.x;
    }
    if (item.y < allElesXY.minY) {
      allElesXY.minY = item.y;
    }
    if (item.x + item.width > allElesXY.maxX) {
      allElesXY.maxX = item.x + item.width;
    }
    if (item.y + item.height > allElesXY.maxY) {
      allElesXY.maxY = item.y + item.height;
    }
  });
  allElesXY.center = [(allElesXY.minX + allElesXY.maxX) / 2, (allElesXY.minY + allElesXY.maxY) / 2];
  let scale = 1;
  if (allElesXY.minX < 0 || allElesXY.maxX > option.processingArea.width || allElesXY.minY < 0 || allElesXY.maxY > option.processingArea.height) {
    handleZoomed(1);
    const iii =
      allElesXY.center[0] > option.processingArea.width / 2
        ? option.processingArea.width / 2 / (Math.abs(allElesXY.maxX) - option.processingArea.width / 2)
        : option.processingArea.width / 2 / (Math.abs(allElesXY.minX) + option.processingArea.width / 2);

    const jjj =
      allElesXY.center[1] > option.processingArea.height / 2
        ? option.processingArea.height / 2 / (Math.abs(allElesXY.maxY) - option.processingArea.height / 2)
        : option.processingArea.height / 2 / (Math.abs(allElesXY.minY) + option.processingArea.height / 2);
    if (Math.min(iii, jjj) < 1) {
      scale = Math.min(iii, jjj) - 0.05;
    }
  }
  setTimeout(() => {
    handleZoomed(scale);
    props.editorStore.editorSetZoom(scale);
  }, 10);
};
watch(
  () => props.currentId,
  () => {
    if (props.currentId && mCanvasManager.value) {
      canvas.value = mCanvasManager.value.getCanvas(props.currentId);
    }
  },
);

onMounted(() => {
  nextTick(() => {
    if (process.client) {
      init();
    }
  });
});
</script>
<style lang="less" scoped>
.canvas-list {
  width: 100%;
  .canvas-wrap {
    height: 345px;
    // height: 100%;
    position: relative;
    border: 1px dashed rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    overflow: hidden;
    &.canvas-mask {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: calc(~'100% - 26px');
        top: 0;
        left: 0;
        z-index: 5;
      }
    }
    .btns {
      position: absolute;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      left: 0px;
      bottom: 0px;
      height: 26px;
      width: calc(100% - 2px);
      background: #fff;
      box-shadow:
        0px 0px 10px 0px rgba(0, 0, 0, 0.1),
        0px 0px 1px 0px rgba(0, 0, 0, 0.06);

      display: flex;
      overflow-y: hidden;
      overflow-x: scroll;

      /* 滚动条整体 */
      &::-webkit-scrollbar {
        width: 0px;
        display: none;
      }
      /* 两个滚动条交接处 -- x轴和y轴 */
      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        width: 0px;
        &:vertical {
          background-color: transparent;
        }
      }
      /* 滚动条轨道 */
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      .btn {
        // width: 97px;
        padding: 0 16px;
        color: theme('colors.text.secondary');
        cursor: pointer;
        display: inline-block;
        line-height: 26px;
        text-align: center;
        white-space: nowrap;
        word-break: break-all;
        &.active {
          background: #f4f4f4;
          color: #212121;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .canvas-list {
    .canvas-wrap {
      height: 300px;
      .btns {
        .btn {
          padding: 0 8px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>

<template>
  <section id="m-canvas-zoom" class="zoom-control">
    <xcs-form inline size="tiny" :form-data="initZoomData()" @form-change="handleZoom" />
  </section>
</template>

<script setup lang="ts">
import { XcsForm } from '@xtool/xcs-ui';
const editorStore = ref(null) as any;
const initEditor = async () => {
  const { useEditorStore } = await import('@/stores/editor');
  editorStore.value = useEditorStore();
  nextTick(() => {
    editorStore.value.editorSetScaledFit('auto');
    zoomFormData.value = initZoomData();
  });
};

const zoomFormData = ref(null as any);
onMounted(() => {
  initEditor();
});

const handleZoom = (val: string | number, props: any) => {
  const handlers = {
    'zoomIn': () => {
      editorStore.value.editorIncreaseScaled(true);
    },
    'zoomOut': () => {
      editorStore.value.editorIncreaseScaled(false);
    },
    'scaled': () => {
      console.log(111, val);
      if (['auto', 'width', 'height'].includes(val.toString())) {
        editorStore.value.editorSetScaledFit(val);
        return;
      }
      editorStore.value.editorSetZoom(val);
    },
  };
  const { id } = props;
  const handler = Reflect.get(handlers, id);
  if (handler) {
    handler.call();
  }
  zoomFormData.value = initZoomData();
};
const initZoomData = () => {
  if (!editorStore.value) {
    return {};
  }
  const { scaled } = editorStore.value.currentState;

  return [
    {
      id: 'zoom',
      type: 'group',
      groupStyle: {
        'border': '1px solid var(--n-color)',
        'border-radius': 'var(--n-border-radius)',
      },
      spaceProps: { align: 'center', wrapItem: false },
      children: [
        {
          id: 'zoomOut',
          type: 'button',
          icon: 'zoom-out',
          attrs: {
            bordered: false,
            circle: true,
            size: 'tiny',
            disabled: scaled <= 0.25,
          },
        },
        {
          id: 'scaled',
          type: 'dropdown',
          label: `${(scaled * 100).toFixed(0)}%`,
          attrs: {
            trigger: 'hover',
            style: { textAlign: 'center' },
            options: [
              { key: '1000%', label: '1000%', value: 10 },
              { key: '800%', label: '800%', value: 8 },
              { key: '600%', label: '600%', value: 6 },
              { key: '400%', label: '400%', value: 4 },
              { key: '200%', label: '200%', value: 2 },
              { key: '100%', label: '100%', value: 1 },
              { key: '75%', label: '75%', value: 0.75 },
              { key: '50%', label: '50%', value: 0.5 },
              { key: '25%', label: '25%', value: 0.25 },
              {
                key: 'width',
                label: 'Fit to width',
                // label: t('editor.operation.fit_to_width'),
                value: 'width',
              },
              {
                key: 'height',
                label: 'Fit to height',
                // label: t('editor.operation.fit_to_height'),
                value: 'height',
              },
              {
                key: 'auto',
                label: 'Fit on screen',
                // label: t('editor.operation.zoom_adaptive'),
                value: 'auto',
              },
            ],
            size: 'small',
            button: {
              text: true,
              size: 'tiny',
              style: 'min-width: 40px',
              onClick() {
                editorStore.value.editorSetScaledFit('auto');
                zoomFormData.value = initZoomData();
              },
            },
          },
        },
        {
          id: 'zoomIn',
          type: 'button',
          icon: 'zoom-in',
          attrs: {
            bordered: false,
            circle: true,
            size: 'tiny',
            disabled: scaled >= 40,
          },
        },
      ],
    },
  ];
};
</script>

<style lang="less">
.zoom-control {
  position: absolute;
  z-index: 1000;
  padding: 3px;
  border-radius: 16px;
  z-index: 10;
  left: 20px;
  bottom: 40px;

  :deep(.n-button) {
    vertical-align: top;
    --n-width: 20px;
    --n-height: 20px;
    --n-icon-size: 20px;
    --n-text-color-focus: unset;
    color: #747a7a;
  }
  svg {
    height: 1em;
  }
  #zoomIn,
  #zoomOut {
    font-size: 20px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: inherit;
    background-color: rgba(116, 122, 122, 0.15);
  }

  &:before,
  .form-item:nth-child(2n + 1) {
    opacity: 0.4;
  }

  &:hover {
    &:before,
    .form-item {
      opacity: 1;
    }
  }

  .form-item {
    display: flex;
    align-items: center;
  }
}
symbol#icon-zoom-in,
symbol#icon-zoom-out {
  path {
    fill: currentColor;
  }
}
</style>
